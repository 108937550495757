<template>
  <div class="manual-wrap">
    <search-manual v-if="getSearchCondition"></search-manual>
    <div class="discount-list-wrap">
      <div class="bar-wrap">
        <el-button
          class="create-button"
          type="primary"
          @click="openDialog('ManualDiscountSetting', { action: 'create' })"
        >
          新增
        </el-button>
      </div>
      <el-table
        ref="listTable"
        :data="getManualDiscountList"
        height="100%"
        class="table-basic"
        header-cell-class-name="cus-table-header"
        row-class-name="cus-table-row"
        empty-text="尚無資料"
      >
        <el-table-column prop="event_id" label="活動ID" width="110" />
        <el-table-column prop="object_to" label="折扣類型" width="110">
          <template #default="scope">
            <el-tag v-if="scope.row.object_to === 3" type="warning">整單折扣</el-tag>
            <el-tag v-else-if="scope.row.object_to === 2" type="success">單杯折扣</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="活動名稱" :show-overflow-tooltip="true" />
        <el-table-column prop="event_type" label="內容" width="180">
          <template #default="scope">
            <span v-if="scope.row.event_type === 1">{{ `打${Number(scope.row.event_value) / 10}折` }}</span>
            <span v-else-if="scope.row.event_type === 2">{{ `折抵${scope.row.event_value}元` }}</span>
            <span v-else-if="scope.row.event_type === 3">{{ `變成${scope.row.event_value}元` }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="available_shop_type" label="門市" width="200">
          <template #default="scope">
            <span v-if="scope.row.available_shop_type === 1">全門市</span>
            <span v-else-if="scope.row.available_shop_type === 2">
              {{ getAreaText(scope.row.available_shop_value_array) }}
            </span>
            <span
              v-else-if="scope.row.available_shop_type === 3"
              class="download-text"
              @click="downloadFile(scope.row.available_shop_file_url)"
            >
              指定門市（csv）
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="available_start_time" label="活動日期" width="160">
          <template #default="scope">
            <span v-if="scope.row.time_period_type === 1">不指定時間</span>
            <span v-if="scope.row.time_period_type === 2">
              {{ formatDateTime('YYYY/MM/DD HH:mm', scope.row.available_start_time) }}~
              <br />
              {{ formatDateTime('YYYY/MM/DD HH:mm', scope.row.available_end_time) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="available_status" label="狀態" width="100" />
        <el-table-column label="功能" fixed="right" align="center" width="130">
          <template #default="scope">
            <el-dropdown class="action">
              <span class="el-dropdown-link">
                操作
                <el-icon class="el-icon--right">
                  <arrow-down />
                </el-icon>
              </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item
                    @click="
                      openDialog('ManualDiscountSetting', { action: 'edit', init: scope.row, index: scope.$index })
                    "
                  >
                    編輯
                  </el-dropdown-item>
                  <el-dropdown-item @click="handleDeleteDiscount(scope.row)">刪除</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="pagination"
        v-model:currentPage="currentPage"
        :page-size="getPageSize"
        layout="prev, pager, next, jumper"
        :total="getPageTotal"
        @click="handleCurrentChange"
      />
    </div>
  </div>

  <!-- 彈窗 -->
  <component :is="dialogTemplate"></component>
</template>
<script>
import { getCurrentInstance, defineAsyncComponent, ref, watch } from 'vue';
import { useDialog } from '@/utils/dialog';
import { useRoute, useRouter } from 'vue-router';
import { useGetters, useMutations, useActions } from '@/utils/mapStore';
import { formatDateTime, getFormatDate } from '@/utils/datetime';
import { downloadFile } from '@/utils/download';
import { getAreaText } from '@/utils/define';
export default {
  name: 'manual-discount',
  components: {
    ManualDialog: defineAsyncComponent(() => import('@/components/discount/ManualDialog.vue')),
    SearchManual: defineAsyncComponent(() => import('@/components/discount/SearchManual.vue')),
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const { dialogTemplate, openDialog, closeDialog } = useDialog();
    const route = useRoute();
    const router = useRouter();

    //取得資料
    const { getManualDiscountList, getPageSize, getPageTotal, getSearchCondition } = useGetters('manualDiscount', [
      'getManualDiscountList',
      'getPageSize',
      'getPageTotal',
      'getSearchCondition',
    ]);
    const SET_SEARCH_CONDITION = useMutations('manualDiscount', ['SET_SEARCH_CONDITION']);
    const { doGetManualDiscountList, doGetManualDiscountSummary } = useActions('manualDiscount', [
      'doGetManualDiscountList',
      'doGetManualDiscountSummary',
    ]);
    //取得店家清單
    const doGetStoreList = useActions('common', ['doGetStoreList']);
    doGetStoreList();

    //初始化
    const listTable = ref(null);
    const currentPage = ref(1);
    const query = { page: 1 };
    const searchDataInit = {
      date: [getFormatDate(), getFormatDate()],
      event_id: '',
      name: '',
      available_status: '',
      shop_id: '',
    };
    router.push({ query, replace: true }).then(() => {
      SET_SEARCH_CONDITION(searchDataInit);
      refreshList({ action: 'init' });
    });

    //點擊分頁
    const handleCurrentChange = () => {
      const query = { page: currentPage.value };
      router.push({ query });
    };

    //更新list
    watch(
      () => route.query.page,
      () => {
        if (route.query.page && getSearchCondition.value) {
          currentPage.value = Number(route.query.page);
          refreshList({ action: 'page' });
          listTable.value.scrollBarRef.scrollTo(0, 0);
        }
      }
    );
    const refreshList = async (data) => {
      if (data.action === 'create') {
        //若為新增折扣時，重設搜尋條件
        if (data.event.available_start_time) {
          let searchData = JSON.parse(JSON.stringify(searchDataInit));
          searchData.date = [
            data.event.available_start_time.slice(0, 10),
            data.event.available_start_time.slice(0, 10),
          ];
          SET_SEARCH_CONDITION(searchData);
        } else {
          SET_SEARCH_CONDITION(searchDataInit);
        }
        currentPage.value = 1;
        const query = { page: currentPage.value };
        router.push({ query });
      } else if (data.action === 'edit') {
        if (data.event.available_start_time) {
          let searchData = JSON.parse(JSON.stringify(searchDataInit));
          searchData.date = [
            data.event.available_start_time.slice(0, 10),
            data.event.available_start_time.slice(0, 10),
          ];
          SET_SEARCH_CONDITION(searchData);
        }
      }
      //排除分頁不打summary api
      if (data.action !== 'page') await doGetManualDiscountSummary(getSearchCondition.value);
      //summary會獲得新total，需計算頁碼，因刪除時會有減頁的情況
      if (currentPage.value > Math.ceil(getPageTotal.value / getPageSize.value)) {
        currentPage.value = Math.max(Math.ceil(getPageTotal.value / getPageSize.value), 1);
      }
      //頁碼確認後才打list api
      await doGetManualDiscountList({
        search: getSearchCondition.value,
        start: (currentPage.value - 1) * getPageSize.value,
      });
      closeDialog();
    };

    //刪除折扣
    const doDeleteManualDiscount = useActions('manualDiscount', ['doDeleteManualDiscount']);
    const handleDeleteDiscount = (item) => {
      proxy
        .$confirm(`確定要刪除－${item.name}？`, '確認刪除', {
          confirmButtonText: '刪除',
          cancelButtonText: '取消',
          type: 'warning',
          confirmButtonClass: 'el-button--danger',
        })
        .then(async () => {
          await doDeleteManualDiscount(item.event_id);
          refreshList({ action: 'delete' });
          proxy.$message({
            type: 'success',
            message: '刪除成功',
          });
        });
    };

    //離開清空vuex
    const RESET = useMutations('manualDiscount', ['RESET']);
    watch(
      () => route.path,
      () => {
        if (!route.path.includes('/discount/manual')) RESET();
      }
    );
    return {
      downloadFile,
      getAreaText,
      formatDateTime,
      dialogTemplate,
      openDialog,
      getSearchCondition,
      getManualDiscountList,
      getPageSize,
      getPageTotal,
      listTable,
      currentPage,
      handleCurrentChange,
      refreshList,
      handleDeleteDiscount,
    };
  },
};
</script>
<style lang="scss" scoped>
$padding-unit: 10px;
$row-height: 55px;
$searchbar-height: 70px;
$pagination-height: 50px;

.manual-wrap {
  height: 100%;
  @include basic-search-condition;
}
.discount-list-wrap {
  width: 100%;
  height: 100%;
  padding: $searchbar-height $padding-unit $pagination-height !important;
  position: relative;
  overflow: scroll;
  @include white-bg;
}

.bar-wrap {
  position: absolute;
  display: flex;
  top: calc($searchbar-height / 2);
  right: $padding-unit;
  transform: translateY(-50%);
}
.create-button {
  width: 110px;
  margin-right: 12px;
}
.table-basic {
  width: 100%;
  border-top: 1px solid $el-border-color-lighter;
}
.action {
  cursor: pointer;
  width: 110px;
  @include flex-center-center;
}
.el-dropdown-link {
  color: $color-primary;
}
.pagination {
  position: absolute;
  left: 50%;
  bottom: calc($pagination-height / 2);
  transform: translate(-50%, 50%);
}
</style>
