import store from '../store';
//參數對應文字表
const mapValue = {
  on_site: { label: '現場', value: 'on_site' },
  phone: { label: '電話', value: 'phone' },
  foodpanda: { label: '熊貓', value: 'foodpanda' },
  line: { label: 'Line@', value: 'line' },
  uber_eat: { label: 'Uber Eats', value: 'uber_eat' },
  app: { label: 'App訂單', value: 'app' },
  juton: { label: '揪團', value: 'juton' },
  pick_up: { label: '自取', value: 'pick_up' },
  inside: { label: '內用', value: 'inside' },
  delivery: { label: '外送', value: 'delivery' },
  north: { label: '北部', value: 'north' },
  central: { label: '中部', value: 'central' },
  south: { label: '南部', value: 'south' },
  east: { label: '東部', value: 'east' },
  voucher: { label: '商品券', value: 'voucher' },
  coupon: { label: '優惠券', value: 'coupon' },
};

const mapOrderStatus = {
  1: { label: '新訂單', value: 1, type: 'success' },
  2: { label: '取消', value: 2, type: 'danger' },
  3: { label: '終止', value: 3, type: 'danger' },
  10: { label: '已結帳', value: 10, type: 'success' },
  15: { label: '掛單', value: 15, type: 'warning' },
  16: { label: '待沖銷', value: 16, type: 'warning' },
  18: { label: '作廢', value: 18, type: 'danger' },
};

const mapInvoiceStatus = {
  0: { label: '未開立', value: 0 },
  1: { label: '已開立', value: 1 },
  2: { label: '已作廢', value: 2 },
  3: { label: '不需開立', value: 3 },
};
const mapAvailableStatus = {
  1: { label: '未開始', value: 1 },
  2: { label: '進行中', value: 2 },
  3: { label: '已結束', value: 3 },
};

//取得區域文字
const getAreaText = (arr) => {
  let text_arr = [];
  arr.forEach((e) => {
    text_arr.push(mapValue[e].label);
  });
  return text_arr.join('、');
};

//取得消費者類型
const getConsumer = (member_card, want_card) => {
  let text = '';
  if (member_card) text = 'APP會員';
  else if (want_card) text = '旺卡會員';
  else text = '一般消費者';
  return text;
};

//各式選項
const source_options = [
  { label: 'APP訂單', value: 'app' },
  { label: '現場', value: 'on_site' },
  { label: '電話', value: 'phone' },
  { label: '熊貓', value: 'foodpanda' },
  { label: 'Line@', value: 'line' },
  { label: 'Uber Eats', value: 'uber_eat' },
  { label: '揪團', value: 'juton' },
];
const source_options_without_inside = [
  { label: '現場', value: 'on_site' },
  { label: '電話', value: 'phone' },
  { label: '熊貓', value: 'foodpanda' },
  { label: 'Line@', value: 'line' },
  { label: 'Uber Eats', value: 'uber_eat' },
];
const not_cut_invoices_options = [
  { label: '現場', value: 'no_cut_invoice.on_site', disabled: 'on_site' },
  { label: '電話', value: 'no_cut_invoice.phone', disabled: 'phone' },
  { label: '熊貓', value: 'no_cut_invoice.foodpanda', disabled: 'foodpanda' },
  { label: 'Line@', value: 'no_cut_invoice.line', disabled: 'line' },
  { label: 'Uber Eats', value: 'no_cut_invoice.uber_eat', disabled: 'uber_eat' },
];
const take_away_options = [
  { label: '自取', value: 'pick_up' },
  { label: '內用', value: 'inside' },
  { label: '外送', value: 'delivery' },
];
const card_pay_options = [
  { label: '信用卡', value: 'credit_card' },
  { label: '悠遊卡', value: 'easy_card' },
  { label: '一卡通', value: 'i_pass' },
];
const third_party_pay_options = [
  { label: 'Line Pay', value: 'line_pay' },
  { label: '街口支付', value: 'jeko' },
  { label: '悠遊付', value: 'uupay' },
  { label: '台灣Pay', value: 'taiwan_pay' },
  { label: '支付寶', value: 'ali_pay' },
];
const other_pay_options = [
  { label: '禮券', value: 'gift_certificate' },
  { label: '園區員工卡', value: 'park_employee_card' },
];
const object_to_options = [
  { label: '整單折扣', value: 3 },
  { label: '單杯折扣', value: 2 },
];
const time_period_type_options = [
  { label: '不指定', value: 1 },
  { label: '指定區段', value: 2 },
];
const event_type_options = [
  { label: '%折數', value: 1 },
  { label: '折抵金額', value: 2 },
  { label: '改價', value: 3 },
];
const event_type_options_giveaway = [
  { label: '%折數', value: 1 },
  { label: '折抵金額', value: 2 },
  { label: '改價', value: 3 },
  { label: '贈品', value: 11 },
  { label: '等價贈品', value: 12 },
];
const available_shop_type_options = [
  { label: '全門市', value: 1 },
  { label: '指定區域', value: 2 },
  { label: '指定門市（csv）', value: 3 },
];
const area_options = [
  { label: '北部', value: 'north' },
  { label: '中部', value: 'central' },
  { label: '南部', value: 'south' },
  { label: '東部', value: 'east' },
];
const event_rule_category_options = [
  { label: '全部', value: 1 },
  { label: '類別', value: 2 },
  { label: '單品項', value: 3 },
];
const event_rule_type_options = [
  { label: '任選', value: 1 },
  { label: '組合', value: 2 },
  { label: '滿額', value: 3 },
  { label: '整單', value: 4 },
];
const event_range_options = [
  { label: '全品項加起來折', value: 1 },
  { label: '折最低價品項', value: 2 },
];
const consumer_options = [
  { label: '一般消費者', value: 'customer' },
  { label: 'APP會員', value: 'app_member' },
  { label: '旺卡會員', value: 'card_member' },
];
const consumer_options_2 = [
  { label: '全部', value: 'all' },
  { label: '一般消費者', value: 'customer' },
  { label: 'APP會員', value: 'app_member' },
  { label: '旺卡會員', value: 'card_member' },
];
const card_member_type_options = [
  { label: '衷心會員', value: 'regular_member' },
  { label: '黃金會員', value: 'gold_member' },
  { label: '尊寵會員', value: 'vip_member' },
];
const card_member_options = [
  { label: '全部', value: 1 },
  { label: '旺卡等級', value: 2 },
  { label: '旺卡卡號(csv)', value: 3 },
];
const app_member_options = [
  { label: '全部', value: 1 },
  { label: '會員號碼(csv)', value: 2 },
];
const qrcode_options = [
  { label: '不設定', value: 1 },
  { label: '網址', value: 2 },
  // { label: '序號', value: 3 },
];
const printing_time_options = [
  { label: '訂單結帳後', value: 1 },
  { label: '滿額', value: 2 },
  { label: '旺卡加值', value: 3 },
];
const redeem_status_options = [
  { label: '未兌換', value: 1 },
  { label: '已兌換', value: 2 },
  { label: '取消兌換', value: 3 },
  { label: '已取消(待同步)', value: 4 },
];
const product_category_status_options = [
  { label: '未上架', value: '未上架' },
  { label: '已上架', value: '已上架' },
];

//商品選項對照
const mapOptionsLabel = (val) => {
  const list = store.getters['common/getOptionsList'];
  return list.find((e) => e.id === val)?.name;
};

//商品名稱對照表
const mapProductLabel = (val) => {
  const list = store.getters['common/getProductList'];
  return list.find((e) => e.id === val)?.name;
};

//商品類別對照表
const mapCategoryLabel = (val) => {
  const list = store.getters['common/getCategoryList'];
  return list.find((e) => e.id === val)?.name;
};

export {
  mapValue,
  mapOrderStatus,
  mapInvoiceStatus,
  mapAvailableStatus,
  getAreaText,
  getConsumer,
  source_options,
  source_options_without_inside,
  not_cut_invoices_options,
  take_away_options,
  card_pay_options,
  third_party_pay_options,
  other_pay_options,
  object_to_options,
  time_period_type_options,
  event_type_options,
  event_type_options_giveaway,
  available_shop_type_options,
  area_options,
  event_rule_category_options,
  event_rule_type_options,
  event_range_options,
  consumer_options,
  consumer_options_2,
  card_member_type_options,
  card_member_options,
  app_member_options,
  qrcode_options,
  printing_time_options,
  redeem_status_options,
  product_category_status_options,
  mapOptionsLabel,
  mapProductLabel,
  mapCategoryLabel,
};
